<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div :class="['verify-old', {'hidden' : !hasBind}]">
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">手机号</label>
              </div>
              <div class="weui-cell__bd">
                <p>{{bindMobile}}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="old-mcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入验证码"
                    v-model="captcha"
                  />
                </p>
              </div>
              <div class="weui-cell__ft" @click="sendSms" v-if="!countDownStatus">
                <button class="weui-vcode-btn btn-verify public-btn-vcode" >
                  身份验证
                </button>
              </div>
              <div class="weui-cell__ft" v-else>
                <span class="weui-vcode-btn btn-verify-new public-btn-vcode disabled">{{seconds}}s 后发送</span>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <input
                  type="hidden"
                  name="action"
                  id="action"
                  value="edit_mobile"
                />
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary public-btn-user-edit-next"
                  @click="willValidate"
                  >下一步</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="['set-new', {'hidden' : hasBind}]">
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">手机号</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input number-input"
                    id="mobile"
                    type="tel"
                    maxlength="11"
                    placeholder="请输入手机号"
                    v-model="mobile"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="mobile-vcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                    v-model="captchaNew"
                  />
                </p>
              </div>
              <div class="weui-cell__ft" v-if="!countDownStatus">
                <button
                  class="weui-vcode-btn btn-verify-new public-btn-vcode"
                  data-field="mobile"
                  @click="sendSmsNew"

                >
                  获取验证码
                </button>
                
              </div>
              <div class="weui-cell__ft" v-else>
                <span class="weui-vcode-btn btn-verify-new public-btn-vcode disabled">{{seconds}}s 后发送</span>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary btn-save-new"
                  data-field="mobile"
                  @click="willBindMobile"
                  >保存</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <tabbar :selectedVal="'users'"></tabbar> -->
  </div>
</template>

<script>
import { getUserInfo, getBindSmsCaptcha, validateChangeCaptcha, getSmsCaptcha, bindMobile, setMobile } from '../api/index'
// import tabbar from '../components/tabbar/index.vue'
export default {
  name: "editMobile",
  components: {
    // tabbar
  },
  data () {
    return {
      countDownStatus: false, // 发送验证码的60秒倒计时内
      timer: null,
      seconds: 60,
      hasBind: false, // 是否已经绑定手机号
      bindMobile: '', // 已经绑定的手机号
      mobile: '', // 输入的手机号
      bizCode: 'SetMobile',
      captcha: '',
      requestId: '',
      uniqueId: '',
      captchaNew: '', // 短信验证码
      requestIdNew: '',
      uniqueIdNew: '',
    }
  },
  created () {
    this.doGetUserInfo()
  },
  methods: {
    // 获取用户信息
    async doGetUserInfo () {
      let params = {
        param: {}
      }
      let res = {}
      try {
        res = await getUserInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          if (result.mobile) {
            this.bindMobile = result.mobile
            this.bizCode = 'ChangeMobile'
            this.hasBind = true
          }
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 发送验证码
    async doGetSmsCaptcha () {
      let params = {
        param: {
          mobile: this.mobile,
          bizCode: this.bizCode
        }
      }
      let res = {}
      try {
        res = await getSmsCaptcha(params)
        this.$toast.loading('加载中');
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.uniqueIdNew = result.uniqueId
          this.$toast.info('验证码已发送，请查收')
          this.countDown()
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        this.$toast.info('活动火爆，请稍后重试')
        console.log('err:', err)
      }
    },
    // 发送绑定的手机号的验证码
    async doGetBindSmsCaptcha () {
      let params = {
        param: {
          bizCode: 'ChangeMobilePrecheck'
        }
      }
      let res = {}
      try {
        res = await getBindSmsCaptcha(params)
        this.$toast.loading('加载中');
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.uniqueId = result.uniqueId
          this.$toast.info('验证码已发送，请查收')
          this.countDown()
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        this.$toast.info('活动火爆，请稍后重试')
        console.log('err:', err)
      }
    },
    // 预验证绑定的手机号
    async doValidateChangeCaptcha () {
      let params = {
        param: {
          captcha: this.captcha,
          uniqueId: this.uniqueId
          // captcha: '671368',
          // uniqueId: 'daf98149a676e26010a5a39c57bb96acca1a2308ae3aa8945273d00f57da5f56'
        }
      }
      let res = {}
      try {
        res = await validateChangeCaptcha(params)
        this.$toast.loading('加载中');
        if (res.code === '0000') {
          this.$toast.hide()
          const passed = res.result.passed
          if (passed === 'Y') {
            this.hasBind = false
          } else {
            this.$toast.info(res.result.resultRemark)
            this.captcha = ''
          }
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        this.$toast.info('活动火爆，请稍后重试')
        console.log('err:', err)
      }
    },
    
    // 绑定手机号
    async doBindMobile () {
      let params = {
        param: {
          newMobile: this.mobile,
          captcha: this.captchaNew,
          captchaUniqueId: this.uniqueIdNew
        }
      }
      let res = {}
      try {
        res = await bindMobile(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.$toast.info('手机号绑定成功')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 更新手机号
    async doSetMobile () {
      let params = {
        param: {
          mobile: this.mobile,
          captcha: this.captchaNew,
          captchaUniqueId: this.uniqueIdNew,
          preCheckCaptcha: this.captcha,
          preCheckCaptchaUniqueId: this.uniqueId
        }
      }
      let res = {}
      try {
        res = await setMobile(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.$toast.info('手机号绑定成功')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    sendSms () {
      if (!bindMobile) {
        this.$toast.info('您暂未绑定手机号')
        return false
      }
      this.doGetBindSmsCaptcha()
    },
    sendSmsNew () {
      if (!this.mobile) {
        this.$toast.info('请输入手机号')
        return false
      } else if (!/^1\d{10}$/.test(this.mobile)) {
        this.$toast.info('请输入正确的手机号')
        return false
      }
      this.doGetSmsCaptcha()
    },
    willBindMobile () {
      if (this.bindMobile) {
        this.doSetMobile()
      } else {
        this.doBindMobile()
      }
    },
    willValidate () {
      this.doValidateChangeCaptcha()
    },
    countDown () {
      this.countDownStatus = true
      this.timer = setInterval(() => {
        this.seconds--
        if (this.seconds === 0) {
          this.countDownStatus = false
          this.seconds = 60
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
};
</script>

<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.btn-save-new{
  padding: unset;
}
.weui-btn.public-btn-user-edit-next{
  padding: unset;
}
</style>
